import { useEffect, useState } from "react";

export function useIsScrolled() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const onScroll: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        setIsScrolled(!entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(onScroll);

    const elementName = "scroll-watch";
    const target = document.getElementById(elementName) || document.createElement("div");
    if (!target.isConnected) {
      target.id = elementName;
      target.style.cssText = "position:absolute;top:0;";
      document.body.appendChild(target);
    }

    observer.observe(target);
    return () => observer.unobserve(target);
  }, []);

  return isScrolled;
}
