import cn from "classnames";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { ReactNode, useState } from "react";

import { LoadingDots } from "~/components/ui";

type ChartProps = {
  isLoading: boolean;
  options: Highcharts.Options | undefined;
  size?: "1" | "1/2";
  title?: string;
  children?: ReactNode;
  onSeriesClick?: () => void;
};

function Debug({ options }: { options?: Highcharts.Options }) {
  return (
    <pre className="h-72 resize-y overflow-auto bg-accent-2 p-4 text-xs">
      {JSON.stringify(options, null, 2)}
    </pre>
  );
}

const Chart: React.FC<React.PropsWithChildren<ChartProps>> = ({
  options,
  title,
  size,
  children,
  isLoading,
}) => {
  const [showConfig, setShowConfig] = useState(false);
  const chartStyle = cn("relative flex-shrink w-full border box-border col-span-2", {
    "md:col-span-1": size === "1/2" || size === undefined,
    "": size === "1",
  });

  return (
    <>
      <div className={chartStyle}>
        {title && (
          <div className="flex justify-between p-2 font-bold">
            {title}
            <div className="flex justify-items-end gap-2">
              {children} <button onClick={() => setShowConfig(!showConfig)}>🐛</button>
            </div>
          </div>
        )}
        <div className="h-[400px]">
          {isLoading && (
            <div className="absolute inset-0 z-10 flex h-96 w-full justify-around bg-white/50">
              <LoadingDots />
            </div>
          )}
          {options && <HighchartsReact highcharts={Highcharts} options={options} />}
        </div>
        {showConfig && <Debug options={options} />}
      </div>
    </>
  );
};

export default Chart;
