import React, { FC } from "react";

const DropdownItemGroup: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div className="py-1" role="none">
      {children}
    </div>
  );
};

export default DropdownItemGroup;
