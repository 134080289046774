import { FC, HTMLAttributes } from "react";

import Button from "./Button";

const UploadButton: FC<React.PropsWithChildren<HTMLAttributes<HTMLInputElement>>> = ({
  onChange,
}) => {
  return (
    <div className="group relative w-28">
      <Button className="w-full group-hover:bg-blue-600" color="primary">
        Select QFX
      </Button>
      <input
        type="file"
        onChange={onChange}
        className="
          absolute
          inset-0 
          w-full opacity-0 file:mr-4 file:w-full
          file:border-0 file:bg-gray-100 file:px-4
          file:py-2 file:text-sm file:content-['test']
          hover:file:cursor-pointer hover:file:bg-gray-200
          "
      />
    </div>
  );
};

export default UploadButton;
