import dayjs from "dayjs";
import React from "react";

import { ChevronLeft, ChevronRight } from "../../icons";

type MonthSelectProps = {
  date: dayjs.Dayjs;
  onChange: (date: dayjs.Dayjs) => void;
  monthsOfData?: number;
};

const MonthSelect: React.FC<React.PropsWithChildren<MonthSelectProps>> = ({ date, onChange }) => {
  function plusMonth() {
    onChange(date.add(1, "month"));
  }

  function plusYear() {
    onChange(date.add(1, "year"));
  }

  function minusMonth() {
    onChange(date.subtract(1, "month"));
  }

  function minusYear() {
    onChange(date.subtract(1, "year"));
  }

  function resetToToday() {
    onChange(dayjs().startOf("month"));
  }

  return (
    <div className="flex items-center bg-white">
      <button onClick={minusMonth} className="text-gray-400">
        <ChevronLeft />
      </button>
      <button className="w-8" onClick={resetToToday}>
        {date.format("MMM")}
      </button>
      <button onClick={plusMonth} className="text-gray-400">
        <ChevronRight />
      </button>
      <button onClick={minusYear} className="text-gray-400">
        <ChevronLeft />
      </button>
      <button className="w-10" onClick={resetToToday}>
        {date.year()}
      </button>
      <button onClick={plusYear} className="text-gray-400">
        <ChevronRight />
      </button>
    </div>
  );
};

export default MonthSelect;
