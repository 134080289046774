import cn from "classnames";
import React, { FC, HTMLAttributes } from "react";

type LabelProps = HTMLAttributes<HTMLLabelElement>;

const Lablel: FC<React.PropsWithChildren<LabelProps>> = ({ children, className, ...rest }) => {
  return (
    <label className={cn("text-sm mt-1", className)} {...rest}>
      {children}
    </label>
  );
};

export default Lablel;
