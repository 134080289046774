import { Combobox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { FC, FocusEvent, Fragment, useState } from "react";

import { Operations } from "~/api";
import { Pill } from "~/components/ui";
import { XOR } from "~/utils/type";

interface TagMultiSelectBaseProps {
  onChange: (tags: Components.Schemas.Tag[]) => void;
  disabled?: boolean;
  className?: string;
  allow_new?: boolean;
  include_inactive?: boolean;
}

interface TagMultiSelectObjProps extends TagMultiSelectBaseProps {
  tags: Components.Schemas.Tag[];
}

interface TagMultiSelectIdProps extends TagMultiSelectBaseProps {
  tag_ids: number[];
}

function TagItem({
  tag,
  onDelete,
  onClick,
}: {
  tag: Components.Schemas.Tag;
  onClick?: (tag: Components.Schemas.Tag) => void;
  onDelete?: (tag: Components.Schemas.Tag) => void;
}) {
  return (
    <Pill
      key={tag.id}
      bgColor={tag.background_color || "lightgray"}
      txColor={tag.color}
      {...(onClick
        ? {
            onClick: () => {
              onClick(tag);
            },
          }
        : {})}
      {...(onDelete
        ? {
            onDelete: () => {
              onDelete(tag);
            },
          }
        : {})}
    >
      {tag.name}
    </Pill>
  );
}

export const TagMultiSelect: FC<XOR<TagMultiSelectObjProps, TagMultiSelectIdProps>> = ({
  tags = [],
  tag_ids,
  onChange,
  className,
  allow_new = false,
  include_inactive = false,
  ...rest
}) => {
  const [query, setQuery] = useState("");
  const { data: all_tags } = Operations.useTags();

  if (tag_ids) {
    tags = tag_ids.map((id) => ({ id }) as Components.Schemas.Tag) || [];
  }

  if (!all_tags) return <></>;

  const selectedTags = tags.map((t) =>
    t.id ? all_tags.find((a) => a.id === t.id) : t,
  ) as Components.Schemas.Tag[];

  function deselect(tag: Components.Schemas.Tag) {
    onChange(selectedTags.filter((t) => t.id !== tag.id));
  }

  function exists(name: string) {
    return !!all_tags!.find((t) => t.name == name);
  }

  return (
    <Combobox
      multiple
      value={selectedTags}
      onChange={(tags) => {
        onChange(tags);
      }}
      {...rest}
    >
      <div className={cn("relative", className)}>
        <div className="relative w-full cursor-default bg-white text-left">
          <span className="flex flex-wrap gap-2">
            {selectedTags.map((tag) => {
              if (!tag) return "";

              return (
                <TagItem
                  key={tag.id}
                  tag={tag}
                  onDelete={() => {
                    deselect(tag);
                  }}
                />
              );
            })}
            <div className="relative w-full">
              <Combobox.Input
                className={cn(
                  "bg-primary px-2 py-1 border w-full pr-8",
                  "focus:outline-none focus:shadow-outline-normal",
                )}
                onChange={(event) => setQuery(event.target.value)}
                autoComplete="off"
                autoCorrect="off"
                placeholder="Tags"
                onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
              />
              <Combobox.Button
                className="absolute inset-y-0 right-0 flex items-center pr-2"
                onClick={() => setQuery("")}
              >
                <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
              </Combobox.Button>
            </div>
          </span>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options static>
            <div className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow focus:outline-none sm:text-sm">
              {allow_new && query && !exists(query) && (
                <Combobox.Option key={0} value={{ id: 0, name: query }} as={Fragment}>
                  {({ active }) => (
                    <li
                      className={cn("truncate whitespace-nowrap p-2 cursor-pointer", {
                        "bg-blue-500 text-white": active,
                      })}
                    >
                      <TagItem tag={{ id: 0, name: query }} />
                    </li>
                  )}
                </Combobox.Option>
              )}

              {all_tags
                ?.filter((t) => t.name?.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
                .filter((t) => !selectedTags.includes(t))
                .filter((t) => (include_inactive ? true : t.is_active))
                .map((t) => (
                  <Combobox.Option key={t.id} value={t} as={Fragment} disabled={!t.is_active}>
                    {({ active }) => (
                      <li
                        className={cn("truncate whitespace-nowrap p-2 cursor-pointer", {
                          "bg-blue-500 text-white": active,
                          "bg-red-200": !t.is_active,
                        })}
                      >
                        <TagItem tag={t} />
                      </li>
                    )}
                  </Combobox.Option>
                ))}
            </div>
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
