import React, { FC } from "react";
import { Controller } from "react-hook-form";

import { OperationForm } from "~/components/operations/modals/EditOperationModal/EditForm";

import { TagMultiSelect } from "./TagMultiSelect";

type SelectTagsWrapperProps = {
  name: keyof OperationForm;
  placeholder: string;
  control: any;
  allow_new?: boolean;
  include_inactive?: boolean;
};

export const TagMultiSelectWrapper: FC<SelectTagsWrapperProps> = ({
  placeholder,
  name,
  control,
  allow_new = false,
  include_inactive = false,
}) => {
  return (
    <>
      <label className="text-right">{placeholder}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TagMultiSelect
            tags={field.value}
            onChange={(tags) => field.onChange(tags)}
            className="col-span-2"
            allow_new={allow_new}
            include_inactive={include_inactive}
          />
        )}
      />
    </>
  );
};

export default TagMultiSelectWrapper;
