import { Combobox, Transition } from "@headlessui/react";
import cn from "classnames";
import React, { FC, Fragment, useState } from "react";

import { Operations } from "~/api";

interface SelectTagsProps {
  type: "payee" | "memo";
  value: string;
  onChange: (text: string) => void;
  className?: string;
}

function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const TextAutoComplete: FC<React.PropsWithChildren<SelectTagsProps>> = ({
  value,
  onChange,
  type,
  className,
}) => {
  const [selectedText, setSelectedText] = useState({ text: value, count: 1 });
  const [query, setQuery] = useState(value);

  const filteredSuggestions = Operations.useHint(type, query);

  return (
    <Combobox
      value={selectedText}
      onChange={(hint) => {
        setSelectedText(hint);
        return onChange(hint.text);
      }}
    >
      <div className={cn("relative w-full", className)}>
        <div className="relative w-full cursor-default bg-white ">
          <Combobox.Input
            className="w-full border bg-primary px-2 py-1 focus:shadow-outline-normal focus:outline-none"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(hint: Components.Schemas.Hint) => hint.text}
            placeholder={capitalizeFirstLetter(type)}
          />
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base focus:outline-none sm:text-sm">
            <Combobox.Option value={{ text: query, count: 1 }} as={Fragment}>
              {({ active, selected }) => (
                <li
                  className={cn("truncate whitespace-nowrap  p-1", {
                    "bg-blue-500 text-white": active,
                    "bg-white text-black": !active,
                    "font-bold": selected,
                  })}
                >
                  {query ? query : `Clear ${type}`}
                </li>
              )}
            </Combobox.Option>

            {filteredSuggestions.data
              ?.filter((item) => item.text != query)
              .map((hint) => (
                <Combobox.Option key={hint.text} value={hint} as={Fragment}>
                  {({ active, selected }) => (
                    <li
                      className={cn("truncate whitespace-nowrap p-1", {
                        "bg-blue-500 text-white": active,
                        "bg-white text-black": !active,
                        "font-bold": selected,
                      })}
                    >
                      {hint.text} {hint.count}
                    </li>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
