import React, { FC, useRef } from "react";

import { useUserAvatar } from "~/utils/hooks/useUserAvatar";
interface Props {
  className?: string;
  children?: any;
}

const Avatar: FC<React.PropsWithChildren<Props>> = () => {
  const ref = useRef<HTMLInputElement>(null);
  const { userAvatar } = useUserAvatar();

  return (
    <div
      ref={ref}
      {...(userAvatar ? { style: { backgroundImage: userAvatar } } : {})}
      className="size-8 rounded-full border-2 border-gray-400 hover:border-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
    >
      &nbsp;
      {/* &nbsp; is needed or it will break sizing: https://stackoverflow.com/questions/67260598/parent-div-height-is-incorrect-when-it-contains-another-empty-div-with-display*/}
      {/* Add an image - We're generating a gradient as placeholder  <img></img> */}
    </div>
  );
};

export default Avatar;
