import { useEffect } from "react";

import { useUI } from "~/providers/ManagedUIContext";
import { getRandomPairOfColors } from "~/utils/colors";

type userAvatarHook = {
  userAvatar?: string;
  setUserAvatar?: (arg: string) => void;
};

export const useUserAvatar = (name = "userAvatar"): userAvatarHook => {
  const { context, setUserAvatar } = useUI();

  useEffect(() => {
    const localAvatar = localStorage.getItem(name);
    if (!context.userAvatar && localAvatar) {
      // Get bg from localStorage and push it to the context.
      setUserAvatar(localAvatar);
    }
    if (!localStorage.getItem(name)) {
      // bg not set locally, generating one, setting localStorage and context to persist.
      const bg = getRandomPairOfColors();
      const value = `linear-gradient(140deg, ${bg[0]}, ${bg[1]} 100%)`;
      localStorage.setItem(name, value);
      setUserAvatar(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    userAvatar: context.userAvatar,
    setUserAvatar,
  };
};
