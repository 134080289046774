import { Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/20/solid";
import dayjs from "dayjs";
import React, { FC, Fragment, useEffect } from "react";

import { User } from "~/api";
import { useUI } from "~/providers/ManagedUIContext";
import { useNotification } from "~/providers/Notification";

export const NotificationsBadge: FC<React.PropsWithChildren<unknown>> = () => {
  const { toggleSidebar } = useUI();

  const { data: notifications } = User.useNotifications();
  const { addNotification } = useNotification();

  useEffect(() => {
    if (notifications) {
      for (const notification of notifications.filter(
        (n) => dayjs(n.timestamp) > dayjs().subtract(3, "days"),
      )) {
        addNotification(`${notification.title} ${notification.message}`, notification.type);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      onClick={toggleSidebar}
      type="button"
      className="relative rounded-full p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
    >
      <span className="sr-only">View notifications</span>
      <BellIcon className="size-6" aria-hidden="true" />
      <Transition
        as={Fragment}
        show={notifications && notifications.length > 0}
        enter="transition ease-out"
        enterFrom="opacity-0 scale-0"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-0"
      >
        <span className="absolute -right-2 bottom-0 flex size-5 items-center justify-center rounded-full bg-red-500/80 text-xs font-bold text-white">
          {notifications && notifications.length > 0 && notifications.length}
        </span>
      </Transition>
    </button>
  );
};
