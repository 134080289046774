import cn from "classnames";
import React, { FC, ReactElement } from "react";

interface FormProps extends React.HtmlHTMLAttributes<HTMLFormElement> {
  children: ReactElement[];
}

const Form: FC<React.PropsWithChildren<FormProps>> = ({ children, className, ...rest }) => {
  return (
    <form className={cn("grid grid-cols-3 gap-4 items-baseline", className)} {...rest}>
      {children}
    </form>
  );
};

export default Form;
