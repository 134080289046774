import cn from "classnames";
import React, { FC } from "react";

const FormRow: FC<React.PropsWithChildren<React.HtmlHTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={cn("grid grid-cols-3 gap-4 items-baseline", className)} {...rest}>
      {children}
    </div>
  );
};

export default FormRow;
