import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import cn from "classnames";
import React, { FC, useEffect, useRef } from "react";

import s from "./Sidebar.module.css";

interface SidebarProps {
  children: any;
  onClose: () => void;
}

const Sidebar: FC<React.PropsWithChildren<SidebarProps>> = ({ children, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sidebar = ref.current;

    if (sidebar) {
      disableBodyScroll(sidebar, { reserveScrollBarGap: true });
    }
    return () => {
      if (sidebar) enableBodyScroll(sidebar);
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <div className={cn(s.root)}>
      <div className="absolute inset-0 overflow-hidden">
        <div className={s.backdrop} onClick={onClose} />
        <section className="absolute inset-y-0 right-0 flex max-w-full pl-10 outline-none">
          <div className="size-full md:w-screen md:max-w-md">
            <div className={s.sidebar} ref={ref}>
              {children}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Sidebar;
