import React from "react";
const Size = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fillRule="evenodd" fill="#000">
      <path
        d="M18 6h-7c-.667-.114-1-.448-1-1s.333-.886 1-1h9v9c0 .667-.333 1-1 1s-1-.333-1-1V6zM6 18h7c.667.114 1 .448 1 1s-.333.886-1 1H4v-9c0-.667.333-1 1-1s1 .333 1 1v7z"
        fillRule="nonzero"
      />
      <rect
        opacity=".3"
        transform="matrix(.707107 -.707107 .707107 .707107 -4.970563 12)"
        x="7"
        y="11"
        width="10"
        height="2"
        rx="1"
      />
    </svg>
  );
};

export default Size;
