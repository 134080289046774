import { FC } from "react";
import { Controller } from "react-hook-form";

import { CategorySelect } from "./CategorySelect";

type CategorySelectWrapperProps = {
  name: string;
  placeholder: string;
  control: any;
  onChange?: (a: Components.Schemas.Category) => void;
  disabled?: boolean;
  className?: string;
};

export const CategorySelectWrapper: FC<CategorySelectWrapperProps> = ({
  placeholder,
  name,
  control,
  onChange,
  disabled,
  className = "col-span-2",
}) => {
  function valToTag(id: number): Components.Schemas.Category {
    return { id } as Components.Schemas.Category;
  }

  return (
    <>
      {placeholder && <label className="text-right">{placeholder}</label>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CategorySelect
            category={valToTag(field.value)}
            onChange={(account) => {
              field.onChange(account.id);
              onChange?.(account);
            }}
            className={className}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};
