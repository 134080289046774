import Link from "next/link";
import React, { FC } from "react";

//ideally this should specify a props type but don't want to waste time
// passing rest to a is needed to make accessibility work

export const MyLink: FC<React.PropsWithChildren<any>> = (props) => {
  const { href, children, external, ...rest } = props;
  if (external)
    return (
      <a href={href} {...rest}>
        {children}
      </a>
    );
  else
    return (
      <Link href={href} {...rest}>
        {children}
      </Link>
    );
};
