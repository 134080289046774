import { Listbox, Transition } from "@headlessui/react";
import cn from "classnames";
import React, { ComponentType, FC, Fragment, HTMLAttributes, PropsWithChildren } from "react";

type CurrencySelectProps = {
  selected?: Components.Schemas.CurrencyEnum;
  onChange: (currency: Components.Schemas.CurrencyEnum) => void;
  allowAny?: boolean;
  icon?: FC<React.PropsWithChildren<unknown>>;
};

const currencies = [
  { name: "Any", value: "" },
  { name: "USD", value: "USD" },
  { name: "PLN", value: "PLN" },
  { name: "EUR", value: "EUR" },
];

function getDisplayName(selected: string) {
  const el = currencies.find((i) => i.value === selected);
  return el ? el.name : "";
}

const CurrencySelect: FC<PropsWithChildren<CurrencySelectProps>> = ({
  selected = "",
  onChange,
  allowAny = false,
  icon,
}) => {
  const Icon: ComponentType<PropsWithChildren<HTMLAttributes<HTMLElement>>> = icon as any;

  const allowedCurrencies = allowAny ? currencies : currencies.filter((i) => i.value);

  return (
    <Listbox
      value={selected}
      onChange={(value) => onChange(value as Components.Schemas.CurrencyEnum)}
    >
      <div className="relative">
        <Listbox.Button
          className={cn(
            "relative w-full py-2 pl-2 text-left bg-white rounded cursor-pointer shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm",
            { "pr-10": icon, "pr-2": !icon },
          )}
        >
          <span className="block truncate">{getDisplayName(selected)}</span>
          {icon && (
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <Icon className="size-5 text-gray-400" aria-hidden="true" />
            </span>
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-max overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {allowedCurrencies.map((currency, idx) => (
              <Listbox.Option
                key={idx}
                className={({ active }) =>
                  `${active ? "text-gray-900 bg-gray-100" : "text-gray-900"}
                          cursor-pointer select-none relative py-2 pl-4 pr-4`
                }
                value={currency.value}
              >
                {({ selected, active }) => (
                  <span
                    title={currency.name}
                    className={`${selected ? "font-medium" : "font-normal"} block truncate`}
                  >
                    {currency.name}
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default React.memo(CurrencySelect);
