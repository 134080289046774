import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { useRouter } from "next/router";
import React from "react";

import { User } from "~/api";
import { CurrencySelect } from "~/components/common";
import { useIsScrolled } from "~/utils/hooks/useIsScrolled";

import { MyLink } from "./MyLink";
import { NotificationsBadge } from "./NotificationsBadge";
import { UserMenu } from "./UserMenu";
import Logo from "../Logo";

const navigation = [
  { name: "Dashboard", href: "/" },
  { name: "Operations", href: "/operations" },
  { name: "Settings", href: "/settings" },
];

const TopNav: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isScrolled = useIsScrolled();

  const profile = User.useProfile();

  const navClassName = cn("sticky top-0 z-20 bg-gray-700", {
    ["shadow-magical"]: isScrolled,
  });

  const router = useRouter();
  return (
    <Disclosure as="nav" className={navClassName}>
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block size-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block size-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex shrink-0 items-center">
                  <Logo className="block h-8 w-auto text-white lg:hidden" />
                  <span className="hidden h-8 w-auto text-xl font-bold text-white lg:block">
                    <Logo className="h-8 w-auto">MyMoney</Logo>
                  </span>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <MyLink
                        key={item.name}
                        href={item.href}
                        className={cn(
                          item.href == router.pathname
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "px-3 py-2 rounded-md text-sm font-medium",
                        )}
                        aria-current={item.href == router.pathname ? "page" : undefined}
                      >
                        {item.name}
                      </MyLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center gap-2 pr-2 sm:static sm:inset-auto sm:pr-0">
                {profile.data && (
                  <CurrencySelect
                    onChange={async (currency) => {
                      const newProfile = { ...profile.data!, currency };
                      const options = { optimisticData: newProfile, rollbackOnError: true };
                      profile.mutate(await User.setCurrency(currency), options);
                    }}
                    selected={profile.data.currency}
                  />
                )}
                <NotificationsBadge />
                <UserMenu />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <MyLink
                  key={item.name}
                  href={item.href}
                  className={cn(
                    item.href == router.pathname
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium",
                  )}
                  aria-current={item.href == router.pathname ? "page" : undefined}
                >
                  {item.name}
                </MyLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default React.memo(TopNav);
