import { Combobox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { FC, Fragment, useState, FocusEvent } from "react";

import { Operations } from "~/api";

export type AccountSelectProps = {
  account: Components.Schemas.Account;
  onChange: (newAccount: Components.Schemas.Account) => void;
  disabled?: boolean;
  className: string;
  include_inactive?: boolean;
};

export const AccountSelect: FC<AccountSelectProps> = ({
  account,
  onChange,
  className,
  include_inactive = false,
  ...rest
}) => {
  const { data: accounts } = Operations.useAccounts();

  const tmp = accounts?.find((a) => a.id === account.id);

  const [selectedAccount, setSelectedAccount] = useState(tmp);
  const [query, setQuery] = useState(tmp?.name || "");

  return (
    <Combobox
      value={selectedAccount}
      onChange={(account) => {
        if (!account) return;

        setSelectedAccount(account);
        onChange(account);
      }}
      {...rest}
    >
      <div className={cn("relative", className)}>
        <div className="relative w-full cursor-default bg-white text-left">
          <Combobox.Input
            className={cn(
              "bg-primary px-2 py-1 border w-full pr-8",
              "focus:outline-none focus:shadow-outline-normal",
            )}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(account: Components.Schemas.Account) => account?.name || ""}
            autoComplete="off"
            autoCorrect="off"
            placeholder="Account"
            onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
          />
          <Combobox.Button
            className="absolute inset-y-0 right-0 flex items-center pr-2"
            onClick={() => setQuery("")}
          >
            <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow focus:outline-none sm:text-sm">
            {accounts
              ?.filter(
                (a) =>
                  a.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
                  a.short_number.includes(query),
              )
              .filter((t) => (include_inactive ? true : t.is_active))
              .map((a) => (
                <Combobox.Option key={a.id} value={a} as={Fragment}>
                  {({ active, selected }) => (
                    <li
                      className={cn("truncate whitespace-nowrap p-1 ", {
                        "bg-blue-500 text-white": active,
                        "font-bold": selected,
                      })}
                    >
                      <div>{a.name}</div>
                      <div className="flex justify-between">
                        <span>{a.short_number}</span> <span>{a.currency}</span>
                      </div>
                    </li>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
