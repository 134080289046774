import cn from "classnames";
import React, { FC, PropsWithChildren } from "react";

import { User } from "~/api";
import { Cross, ChevronLeft } from "~/components/icons";
import { UserMenu } from "~/components/ui/TopNav/UserMenu";

import s from "./SidebarLayout.module.css";

type ComponentProps = { className?: string } & (
  | { handleClose: () => any; handleBack?: never }
  | { handleBack: () => any; handleClose?: never }
);

const SidebarLayout: FC<React.PropsWithChildren<PropsWithChildren<ComponentProps>>> = ({
  children,
  className,
  handleClose,
  handleBack,
}) => {
  const { data: notifications } = User.useNotifications();
  return (
    <div className={cn(s.root, className)}>
      <header className={s.header}>
        {handleClose && (
          <button
            onClick={handleClose}
            aria-label="Close"
            className="flex items-center transition duration-150 ease-in-out hover:text-accent-5 focus:outline-none"
          >
            <Cross className="size-6 hover:text-accent-3" />
            <span className="ml-2 text-sm text-accent-7">Close</span>
          </button>
        )}
        {handleBack && (
          <button
            onClick={handleBack}
            aria-label="Go back"
            className="flex items-center transition duration-150 ease-in-out hover:text-accent-5 focus:outline-none"
          >
            <ChevronLeft className="size-6 hover:text-accent-3" />
            <span className="ml-2 text-xs text-accent-7">Back</span>
          </button>
        )}
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <span className="flex size-5 items-center justify-center rounded-full bg-red-500/80 font-bold text-white">
            {notifications?.length}
          </span>
          <UserMenu />
        </div>
      </header>
      <div className={s.container}>{children}</div>
    </div>
  );
};

export default SidebarLayout;
