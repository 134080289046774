import { Combobox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { FC, FocusEvent, Fragment, useState } from "react";

import { Operations } from "~/api";
import { Pill } from "~/components/ui";
import { XOR } from "~/utils/type";

interface CategoryMultiSelectBaseProps {
  onChange: (tags: Components.Schemas.Category[]) => void;
  disabled?: boolean;
  className?: string;
  include_inactive?: boolean;
}

interface CategoryMultiSelectObjProps extends CategoryMultiSelectBaseProps {
  categories: Components.Schemas.Category[];
}

interface CategoryMultiSelectIdProps extends CategoryMultiSelectBaseProps {
  category_ids: number[];
}

export const CategoryMultiSelect: FC<
  XOR<CategoryMultiSelectObjProps, CategoryMultiSelectIdProps>
> = ({
  categories = [],
  category_ids,
  onChange,
  className,
  include_inactive = false,
  ...rest
}) => {
  const [query, setQuery] = useState("");

  const { data: all_categories } = Operations.useCategories();

  if (category_ids) {
    categories = category_ids.map((id) => ({ id }) as Components.Schemas.Category) || [];
  }

  if (!category_ids) return <></>;
  if (!all_categories) return <></>;

  const selectedCategories = categories!.map((c) =>
    all_categories.find((a) => a.id === c.id),
  ) as Components.Schemas.Category[];

  function deselect(category: Components.Schemas.Category) {
    onChange(selectedCategories.filter((c) => c.id !== category.id));
  }

  return (
    <Combobox
      multiple
      value={selectedCategories}
      onChange={(categories) => {
        onChange(categories);
      }}
      {...rest}
    >
      <div className={cn("relative", className)}>
        <div className="relative w-full cursor-default bg-white text-left">
          <span className="flex flex-wrap gap-2">
            {selectedCategories.map((category) => {
              if (!category) return "";

              return (
                <Pill
                  key={category.id}
                  bgColor={category.background_color}
                  txColor={category.color}
                  onDelete={() => {
                    deselect(category);
                  }}
                >
                  {category.name}
                </Pill>
              );
            })}
            <div className="relative w-full">
              <Combobox.Input
                className={cn(
                  "bg-primary px-2 py-1 border w-full pr-8",
                  "focus:outline-none focus:shadow-outline-normal",
                )}
                onChange={(event) => setQuery(event.target.value)}
                autoComplete="off"
                autoCorrect="off"
                placeholder="Categories"
                onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
              />
              <Combobox.Button
                className="absolute inset-y-0 right-0 flex items-center pr-2"
                onClick={() => setQuery("")}
              >
                <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
              </Combobox.Button>
            </div>
          </span>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow focus:outline-none sm:text-sm"
            static
          >
            {all_categories
              ?.filter(
                (c) =>
                  c.full_name?.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
                  c.description?.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
              )
              .filter((c) => !selectedCategories.includes(c))
              .filter((c) => (include_inactive ? true : c.is_active))
              .map((c) => (
                <Combobox.Option key={c.id} value={c} as={Fragment}>
                  {({ active }) => (
                    <li
                      className={cn("truncate whitespace-nowrap p-2 cursor-pointer", {
                        "bg-blue-500 text-white": active,
                        "bg-red-200": !c.is_active || !c.is_assignable,
                      })}
                    >
                      {!c.is_assignable && <LockClosedIcon className="inline h-3" />}
                      <Pill bgColor={c.background_color} txColor={c.color}>
                        {c.full_name}
                      </Pill>
                    </li>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
