import { FC } from "react";
import { Controller } from "react-hook-form";

import { AccountSelect } from "./AccountSelect";

type AccountSelectWrapperProps = {
  name: string;
  placeholder: string;
  control: any;
  onChange?: (a: Components.Schemas.Account) => void;
  disabled?: boolean;
};

export const AccountSelectWrapper: FC<AccountSelectWrapperProps> = ({
  placeholder,
  name,
  control,
  onChange,
  disabled,
}) => {
  function valToTag(id: number): Components.Schemas.Account {
    return { id } as Components.Schemas.Account;
  }

  return (
    <>
      <label className="text-right">{placeholder}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <AccountSelect
            account={valToTag(field.value)}
            onChange={(account) => {
              field.onChange(account.id);
              onChange?.(account);
            }}
            className="col-span-2"
            disabled={disabled}
          />
        )}
      />
    </>
  );
};
