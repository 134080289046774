import { parseCookies, setCookie } from "nookies";
import { useEffect, useState } from "react";

const COOKIE_NAME = "accept_cookies";

type acceptCookiesHook = {
  acceptedCookies: boolean;
  onAcceptCookies: () => void;
};

export const useAcceptCookies = (): acceptCookiesHook => {
  const [acceptedCookies, setAcceptedCookies] = useState(true);

  useEffect(() => {
    const cookies = parseCookies();
    if (!cookies[COOKIE_NAME]) {
      setAcceptedCookies(false);
    }
  }, []);

  const acceptCookies = () => {
    setAcceptedCookies(true);
    setCookie(null, COOKIE_NAME, "accepted", { maxAge: 365 * 60 * 60 * 24 });
  };

  return {
    acceptedCookies,
    onAcceptCookies: acceptCookies,
  };
};
