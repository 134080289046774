import { FC } from "react";
import { Controller } from "react-hook-form";

import { TextAutoComplete } from "./TextAutoComplete";

type TextAutoCompleteWrapperProps = {
  name: string;
  placeholder?: string;
  control: any;
  onChange?: (a: Components.Schemas.Account) => void;
  disabled?: boolean;
  type: "memo" | "payee";
  className?: string;
};

export const TextAutoCompleteWrapper: FC<TextAutoCompleteWrapperProps> = ({
  placeholder,
  name,
  control,
  type,
  className = "col-span-2",
}) => {
  return (
    <>
      {placeholder && <label className="text-right">{placeholder}</label>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextAutoComplete
            value={field.value || ""}
            type={type}
            onChange={(value) => {
              field.onChange(value);
            }}
            className={className}
          />
        )}
      />
    </>
  );
};
