import cn from "classnames";
import React, { forwardRef, ButtonHTMLAttributes, JSXElementConstructor, useRef, FC } from "react";
import { mergeRefs } from "react-merge-refs";

import { LoadingDots } from "~/components/ui";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
  className?: string;
  variant?: "large" | "regular" | "slim";
  color?: "regular" | "primary" | "link" | "danger";
  active?: boolean;
  type?: "submit" | "reset" | "button";
  Component?: string | JSXElementConstructor<any>;
  loading?: boolean;
  disabled?: boolean;
  icon?: FC<React.PropsWithChildren<unknown>>;
}

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = forwardRef((props, buttonRef) => {
  const {
    className,
    variant = "regular",
    color = "regular",
    children,
    active,
    loading = false,
    disabled = false,
    Component = "button",
    icon,
    ...rest
  } = props;

  const Icon: React.ComponentType<React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>> =
    icon as any;
  const ref = useRef<typeof Component>(null);

  //todo this needs rework styles in the sheets dont get overriddern...
  const rootClassName = cn(
    "text-sm",
    "active:bg-gray-300",
    "focus:outline-none focus:ring-2 focus:ring-gray-700",
    "disabled:opacity-50 disabled:hover:bg-transparent disabled:hover:cursor-not-allowed ",
    {
      "bg-accent-1 text-accent-3 border-accent-2 cursor-progress": loading,
      "relative py-2 pr-3 pl-7 text-right": icon,

      "px-3 py-2": variant === "regular",
      "px-4 py-2": variant === "large",
      "px-2 py-1": variant === "slim",
      "bg-gray-100 hover:bg-gray-200": color === "regular",
      "bg-blue-500 text-white hover:bg-blue-600": color === "primary",
      "bg-transparent text-gray-800 hover:bg-transparent hover:underline": color === "link",
      "bg-red-500 text-white hover:bg-red-600": color === "danger",
    },
    className,
  );

  return (
    <Component
      aria-pressed={active}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      className={rootClassName}
      disabled={disabled}
      {...rest}
    >
      {icon && (
        <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
          <Icon className="size-4 " />
        </span>
      )}
      {children}
      {loading && (
        <i className="m-0 flex pl-2">
          <LoadingDots />
        </i>
      )}
    </Component>
  );
});

Button.displayName = "Button";

export default Button;
