import cn from "classnames";
import React, { forwardRef, InputHTMLAttributes } from "react";

export type InputProps = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>((props, inputRef) => {
  const { className, type = "text", autoComplete = "off", ...rest } = props;

  return (
    <input
      ref={inputRef}
      type={type}
      className={cn(
        "bg-primary px-2 py-1 border w-full",
        "focus:outline-none focus:shadow-outline-normal",
        "disabled:bg-gray-100",
        className,
      )}
      autoComplete={autoComplete}
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      {...rest}
    />
  );
});

export default Input;
Input.displayName = "Input";

export const InputWithLabel = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...rest }, ref) => {
    return (
      <>
        <label className="text-right">{rest.placeholder}</label>
        <Input className={cn("col-span-2 ", className)} ref={ref} {...rest} />
      </>
    );
  },
);
InputWithLabel.displayName = "InputWithLabel";

interface AmountInputProps extends InputHTMLAttributes<HTMLInputElement> {
  currency: string;
  allowNegativeValue?: boolean;
}

export const AmountInput = forwardRef<HTMLInputElement, AmountInputProps>(
  ({ className, currency, defaultValue, step = 0.01, ...rest }, ref) => {
    return (
      <>
        <label className="text-right">{rest.placeholder}</label>
        <div className="relative col-span-2">
          <Input
            ref={ref}
            className={cn(
              "text-right w-full pr-14 px-2 py-1",
              "focus:outline-none focus:shadow-outline-normal",
              className,
            )}
            placeholder="Please enter a number"
            type="number"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            step={Number(step)}
            defaultValue={`${defaultValue}`}
            {...rest}
          />
          <div className="absolute inset-y-0 right-0 flex items-center border-l px-2">
            {currency}
          </div>
        </div>
      </>
    );
  },
);
AmountInput.displayName = "AmountInput";
