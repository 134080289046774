import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";
import React, { FC, Fragment } from "react";

import { MyLink } from "./MyLink";
import { Avatar } from "..";

const userMenu = [
  { name: "Your Profile", href: "/profile" },
  { name: "Admin", href: "/admin/", external: true },
  { name: "Sign out", href: "/signout" },
];

export const UserMenu: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Menu as="div" className="relative z-30">
      <div>
        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
          <span className="sr-only">Open user menu</span>
          <Avatar />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
          {userMenu.map((entry) => (
            <Menu.Item key={entry.href}>
              {({ active }) => (
                <MyLink
                  href={entry.href}
                  external={entry.external}
                  className={cn(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm")}
                >
                  {entry.name}
                </MyLink>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
