import cn from "classnames";
import React from "react";

interface Props {
  className?: string;
}

const Heart: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const rootClassName = cn("inline fill-current", className);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className={rootClassName}>
      <path d="M16.5 4.5c-1.61 0-3.492 1.825-4.5 3-1.008-1.175-2.89-3-4.5-3C4.651 4.5 3 6.722 3 9.55c0 3.133 3 6.45 9 9.95 6-3.5 9-6.75 9-9.75 0-2.828-1.651-5.25-4.5-5.25z" />
    </svg>
  );
};
export default Heart;
