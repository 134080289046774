import cn from "classnames";
import React, { FC, HTMLAttributes } from "react";

import { Cross } from "~/components/icons";

interface PillProps extends HTMLAttributes<HTMLSpanElement> {
  txColor?: string | null;
  bgColor?: string | null;
  onClick?: () => void;
  onDelete?: () => void;
}

const Pill: FC<React.PropsWithChildren<PillProps>> = ({
  children,
  txColor,
  bgColor,
  onClick,
  onDelete,
  className,
  ...rest
}) => {
  return (
    <span
      className={cn(
        "inline-flex items-center whitespace-nowrap",
        "px-1 text-xs font-medium rounded py-0.5 bg-opacity-20",
        { "cursor-pointer": onClick },
        { "cursor-help": rest.onContextMenu },
        className,
      )}
      style={{
        ...(txColor ? { color: txColor } : {}),
        ...(bgColor ? { backgroundColor: bgColor } : {}),
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
      {onDelete && (
        <Cross
          className="ml-1 box-content inline-block size-3 cursor-pointer select-none rounded-full p-0.5 hover:bg-gray-400"
          onClick={onDelete}
        />
      )}
    </span>
  );
};

export default Pill;
