import useSWR from "swr";

import { fetchWrapper } from "~/utils/fetchWrapper";

function useNotifications() {
  return useSWR<Paths.UserNotificationsList.Responses.$200>("/api/v1/user/notifications");
}

function logout() {
  return fetchWrapper.post<any, Paths.UserLogoutCreate.Responses.$200>("/api/v1/auth/logout", {});
}

const useProfile = () => {
  return useSWR<Paths.UserProfileRetrieve.Responses.$200>("/api/v1/user/profile");
};

function setCurrency(currency: Components.Schemas.CurrencyEnum) {
  const url = "/api/v1/user/profile";
  return fetchWrapper.patch<
    Paths.UserProfilePartialUpdate.RequestBody,
    Paths.UserProfilePartialUpdate.Responses.$200
  >(url, {
    currency,
  });
}

const api = {
  useNotifications,
  useProfile,
  logout,
  setCurrency,
};

export default api;
