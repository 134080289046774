import React from "react";

import { Heart } from "~/components/icons";

function Footer() {
  const version = process.env.VERSION;

  return (
    <footer className="mx-auto py-8 text-center text-sm">
      &copy; {new Date().getFullYear()} made with <Heart className="text-red-600"></Heart> by lk |{" "}
      {version}
    </footer>
  );
}

export default Footer;
