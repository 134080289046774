import { fetchWrapper } from "~/utils/fetchWrapper";

function trigger_model_regeneration() {
  return fetchWrapper.post<any, Paths.MlTriggerModelRegenerationCreate.Responses.$200>(
    "/api/v1/ml/trigger_model_regeneration",
    {},
  );
}

function trigger_categorization() {
  return fetchWrapper.post<any, Paths.MlTriggerCategorizationCreate.Responses.$200>(
    "/api/v1/ml/trigger_categorization",
    {},
  );
}

const api = {
  trigger_model_regeneration,
  trigger_categorization,
};

export default api;
