import useSWR from "swr";

import { toUrl } from "~/utils/fetchWrapper";

function useMonthlyAmountsByCategory(months = 39) {
  return useSWR<Paths.ReportsMonthlyAmountsByCategoryRetrieve.Responses.$200>(
    toUrl(`/api/v1/reports/monthly_amounts_by_category`, { previousMonths: months }),
  );
}

function useNetWorth() {
  return useSWR<Paths.ReportsMonthlyNetWorthRetrieve.Responses.$200>(
    "/api/v1/reports/monthly_net_worth",
  );
}

const Reports = { useMonthlyAmountsByCategory, useNetWorth };

export default Reports;
