import { useEffect, useState } from "react";
import useSWR from "swr";

import { fetchWrapper, toUrl } from "~/utils/fetchWrapper";

function usePlaidItems() {
  return useSWR<Paths.IntegrationsPlaidList.Responses.$200>("/api/v1/integrations/plaid/");
}

async function _createLinkToken(id: number | null) {
  const data = await fetchWrapper.get<Paths.IntegrationsPlaidCreateLinkTokenCreate.Responses.$201>(
    toUrl("/api/v1/integrations/plaid/create_link_token", { item_id: id }),
  );

  return data;
}

function useLinkToken(item_id: number | null) {
  const [link_token, setToken] = useState<string | null>(null);
  const [expiration, setExpiration] = useState<string | null>(null);

  // get a link_token from your API when component mounts
  useEffect(() => {
    const createLinkToken = async () => {
      const res = await _createLinkToken(item_id);
      setToken(res.link_token);
      setExpiration(res.expiration);
    };
    createLinkToken();
  }, [item_id]);

  return { link_token, expiration };
}

function exchangeForAccessToken(metadata: any) {
  const url = "/api/v1/integrations/plaid/exchange_request";
  return fetchWrapper.post(url, metadata);
}

function resetItem(id: Paths.IntegrationsPlaidPartialUpdate.Parameters.Id) {
  const url = `/api/v1/integrations/plaid/${id}`;
  return fetchWrapper.patch(url, {
    requires_reauthentication: false,
  });
}

const api = {
  useLinkToken,
  exchangeForAccessToken,
  usePlaidItems,
  resetItem,
};

export default api;
