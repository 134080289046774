import cn from "classnames";
import React, { FunctionComponent, JSXElementConstructor, CSSProperties } from "react";

import s from "./Text.module.css";

interface TextProps {
  variant?: Variant;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode | any;
  html?: string;
  onClick?: () => any;
}

type Variant = "heading" | "body" | "pageHeading" | "sectionHeading";

const Text: FunctionComponent<React.PropsWithChildren<TextProps>> = ({
  style,
  className = "",
  variant = "body",
  children,
  html,
  onClick,
}) => {
  const componentsMap: {
    [P in Variant]: React.ComponentType<React.PropsWithChildren<any>> | string;
  } = {
    body: "div",
    heading: "h1",
    pageHeading: "h1",
    sectionHeading: "h2",
  };

  const Component:
    | JSXElementConstructor<any>
    | React.ReactElement<any>
    | React.ComponentType<React.PropsWithChildren<any>>
    | string = componentsMap![variant!];

  const htmlContentProps = html
    ? {
        dangerouslySetInnerHTML: { __html: html },
      }
    : {};

  return (
    <Component
      className={cn(
        s.root,
        {
          [s.body]: variant === "body",
          [s.heading]: variant === "heading",
          [s.pageHeading]: variant === "pageHeading",
          [s.sectionHeading]: variant === "sectionHeading",
        },
        className,
      )}
      onClick={onClick}
      style={style}
      {...htmlContentProps}
    >
      {children}
    </Component>
  );
};

export default Text;
