import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { FC, useEffect, useRef, useState } from "react";

import { ChevronDown } from "~/components/icons";
import ClickOutside from "~/utils/click-outside";

const Dropdown: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      if (showDropdown) {
        disableBodyScroll(ref.current, { reserveScrollBarGap: true });
      } else {
        enableBodyScroll(ref.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [showDropdown]);

  return (
    <ClickOutside onClick={() => setShowDropdown(!showDropdown)} active={showDropdown}>
      <div className="relative inline-block text-left">
        <div>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
          >
            Edit
            <ChevronDown
              className="-mr-1 ml-2 size-5"
              onClick={() => setShowDropdown(!showDropdown)}
            />
          </button>
        </div>

        {showDropdown && (
          <div
            ref={ref}
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            {children}
          </div>
        )}
      </div>
    </ClickOutside>
  );
};

export default Dropdown;
