import { ArrowsRightLeftIcon } from "@heroicons/react/20/solid";

export function CategoryPill(item: Components.Schemas.Operation) {
  return (
    <span className="flex items-center">
      {item.transfer_id && <ArrowsRightLeftIcon className="inline h-3" />}
      <span
        className="text-left"
        style={{
          ...(item.category_color ? { color: item.category_color } : {}),
          ...(item.category_background_color
            ? { backgroundColor: item.category_background_color }
            : {}),
        }}
      >
        {item.category_full_name}
      </span>
    </span>
  );
}
