import React, { FC } from "react";

const DropdownItem: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <a
      href="#"
      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
      role="menuitem"
      tabIndex={-1}
      id="menu-item-0"
    >
      {children}
    </a>
  );
};

export default DropdownItem;
