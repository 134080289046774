import cn from "classnames";
import noop from "lodash/noop";
import React, { FC } from "react";

export type CHECKBOX_STATES = boolean | "INDETERMINATE";

interface CheckboxProps {
  className?: string;
  label?: string;
  onChange?: (value: boolean) => void;
  value?: CHECKBOX_STATES;
}

const Checkbox: FC<React.PropsWithChildren<CheckboxProps>> = ({
  label,
  className,
  onChange = noop,
  value = false,
}) => {
  const checkboxRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!checkboxRef || !checkboxRef.current) return;

    if (value === true) {
      checkboxRef.current.checked = true;
      checkboxRef.current.indeterminate = false;
    } else if (value === false) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = false;
    } else if (value === "INDETERMINATE") {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
    }
  }, [value]);

  return (
    <label className="flex items-center text-sm">
      <input
        ref={checkboxRef}
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
        className={cn(
          "w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500",
          className,
          { "mr-2": label },
        )}
      />
      {label}
    </label>
  );
};

export default Checkbox;
