import cn from "classnames";
import React from "react";

interface Props {
  className?: string;
  onShowMenu: () => void;
}

const Menu: React.FC<React.PropsWithChildren<Props>> = ({ className, onShowMenu }) => {
  const rootClassName = cn("inline fill-current", className);

  //TODO: svg viewbox is for 20x20 update it to 24x24
  return (
    <button
      onClick={onShowMenu}
      className="flex items-center p-1 text-white hover:text-gray-900 focus:outline-none"
    >
      <svg
        viewBox="0 0 20 20"
        width="24"
        height="24"
        className={rootClassName}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
      </svg>
    </button>
  );
};

export default Menu;
